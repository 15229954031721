import { LegacyEvent, typeLegacyEvent } from './LegacyEvent';

export interface NavigateToFoamLegacyEvent extends LegacyEvent {
  type: typeLegacyEvent.navigateToFoam;
  payload: {
    href: string;
  };
}

export function isNavigateToFoamLegacyEvent(
  evnt: LegacyEvent
): evnt is NavigateToFoamLegacyEvent {
  return evnt?.type === typeLegacyEvent.navigateToFoam;
}
