var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/hooks/useSSO.tsx
import jwt_decode from "jwt-decode";
import * as React from "react";
import { useEffect, useState } from "react";
import { useMount } from "react-use";

// src/constants/local_storage.ts
var accessTokenKey = "accessToken";
var refreshTokenKey = "refreshToken";
var creatorIdKey = "creatorId";
var singleTokenKey = "singleToken";
var impersonateAccessTokenKey = "impersonateAccessToken";
var impersonateRefreshTokenKey = "impersonateRefreshToken";

// src/constants/routes.ts
var routes = {
  loginSSO: `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/whalar/login`,
  logoutSSO: `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/whalar/logout`
};

// src/constants/user_roles.ts
var USER_ROLES = /* @__PURE__ */ ((USER_ROLES2) => {
  USER_ROLES2["anonymous"] = "anonymous";
  USER_ROLES2["agency"] = "echo-agency";
  USER_ROLES2["brand"] = "echo-manager";
  USER_ROLES2["brandUser"] = "echo-brand-user";
  USER_ROLES2["creatorEcho"] = "echo-creator";
  USER_ROLES2["creatorManaged"] = "foam-managed-creator";
  USER_ROLES2["creatorFoam"] = "foam-creator";
  USER_ROLES2["talentRep"] = "foam-manager";
  return USER_ROLES2;
})(USER_ROLES || {});

// src/utils/getUrlSearchParams.tsx
var getUrlSearchParams = () => new URLSearchParams(window.location.search);

// src/utils/urlSearchParamsIncludes.tsx
var urlSearchParamsIncludes = (arr) => arr.every(
  (val) => Array.from(getUrlSearchParams().keys()).includes(val)
);

// src/utils/hasSingleTokenInUrl.tsx
var hasSingleTokenInUrl = () => urlSearchParamsIncludes(["singleToken"]);

// src/utils/hasTokensInUrl.tsx
var hasTokensInUrl = () => urlSearchParamsIncludes(["accessToken", "refreshToken"]);

// src/utils/mapURLSearchParamsToParams.tsx
var mapURLSearchParamsToParams = (params) => Array.from(params.keys()).reduce((acc, key) => {
  const value = params.get(key);
  if (value) {
    acc[key] = value;
  }
  return acc;
}, {});

// src/hooks/useSSO.tsx
import { Fragment, jsx } from "react/jsx-runtime";
var SSOContext = React.createContext(null);
function SSOProvider({ children }) {
  const [tokens, setTokens] = useState(null);
  const [role, setRole] = useState("anonymous" /* anonymous */);
  const [user, setUser] = useState(null);
  const persistTokens = (tokens2) => {
    setTokens(tokens2);
    if (tokens2.accessToken && tokens2.refreshToken) {
      window.localStorage.setItem(accessTokenKey, tokens2.accessToken);
      window.localStorage.setItem(refreshTokenKey, tokens2.refreshToken);
      window.localStorage.removeItem(impersonateAccessTokenKey);
      window.localStorage.removeItem(impersonateRefreshTokenKey);
    }
    if (tokens2.singleToken) {
      window.localStorage.setItem(singleTokenKey, tokens2.singleToken);
    } else {
      window.localStorage.removeItem(singleTokenKey);
    }
    if (tokens2.creatorId) {
      window.localStorage.setItem(creatorIdKey, tokens2.creatorId);
    } else {
      window.localStorage.removeItem(creatorIdKey);
    }
  };
  useEffect(() => {
    if (tokens == null ? void 0 : tokens.accessToken) {
      const decoded = jwt_decode(tokens.accessToken);
      setUser({
        id: decoded.sub,
        email: decoded.email,
        username: decoded.preferred_username,
        ip_address: "{{auto}}"
      });
      setRole(
        USER_ROLES[Object.keys(USER_ROLES).find(
          (key) => decoded.realm_access.roles.includes(
            USER_ROLES[key]
          )
        ) || "anonymous" /* anonymous */]
      );
    }
  }, [tokens == null ? void 0 : tokens.accessToken]);
  useMount(() => __async(this, null, function* () {
    try {
      if (hasTokensInUrl()) {
        const params = getUrlSearchParams();
        const { accessToken, refreshToken, creatorId } = mapURLSearchParamsToParams(params);
        persistTokens({
          accessToken,
          refreshToken,
          creatorId
        });
      } else if (hasSingleTokenInUrl()) {
        const params = getUrlSearchParams();
        const { singleToken } = mapURLSearchParamsToParams(params);
        persistTokens({ singleToken });
      } else if (!(tokens == null ? void 0 : tokens.accessToken)) {
        const localStorageTokens = {
          accessToken: window.localStorage.getItem(accessTokenKey),
          refreshToken: window.localStorage.getItem(refreshTokenKey)
        };
        if (localStorageTokens.accessToken && localStorageTokens.refreshToken) {
          setTokens(localStorageTokens);
        } else {
          const url = new URL(`${routes.loginSSO}`);
          if (window.location.pathname !== "/") {
            url.searchParams.append(
              "redirectUrl",
              `${window.location.toString()}`
            );
          }
          window.location.assign(url);
        }
      }
    } catch (e) {
    }
  }));
  const logout = () => {
    setUser(null);
    window.localStorage.removeItem(accessTokenKey);
    window.localStorage.removeItem(refreshTokenKey);
    window.localStorage.removeItem(impersonateAccessTokenKey);
    window.localStorage.removeItem(impersonateRefreshTokenKey);
    window.localStorage.removeItem(singleTokenKey);
    window.location.assign(routes.logoutSSO);
  };
  const refresh = () => {
  };
  const providerValue = {
    logout,
    refresh,
    role,
    accessToken: tokens == null ? void 0 : tokens.accessToken,
    refreshToken: tokens == null ? void 0 : tokens.refreshToken,
    singleToken: tokens == null ? void 0 : tokens.singleToken,
    user
  };
  return /* @__PURE__ */ jsx(Fragment, { children: /* @__PURE__ */ jsx(SSOContext.Provider, { value: providerValue, children: (tokens == null ? void 0 : tokens.accessToken) || (tokens == null ? void 0 : tokens.singleToken) ? children : /* @__PURE__ */ jsx(Fragment, {}) }) });
}
var useSSO = () => {
  const context = React.useContext(SSOContext);
  if (!context) {
    throw new Error("useSSO must be used within SSOProvider");
  }
  return context;
};
export {
  SSOProvider,
  USER_ROLES,
  useSSO
};
