import { LegacyEvent, typeLegacyEvent } from './LegacyEvent';

export interface NavigateLegacyEvent extends LegacyEvent {
  type: typeLegacyEvent.navigate;
  payload: {
    pathname: string;
    title: string;
  };
}

export function isNavigateLegacyEvent(
  evnt: LegacyEvent
): evnt is NavigateLegacyEvent {
  return evnt?.type === typeLegacyEvent.navigate;
}
