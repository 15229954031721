import { useDisclosure } from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';

import Modal from '~/components/Modal';

export interface ModalProps {
  title?: string;
  modalBody: React.ReactElement;
  modalFooter?: React.ReactElement;
}

export const defaultValueModal: ModalProps = {
  title: '',
  modalBody: <></>,
  modalFooter: <></>,
};

type ModalContextType = {
  modalProps: ModalProps;
  setModalProps: React.Dispatch<React.SetStateAction<ModalProps>>;
  onOpen(): void;
  onClose(): void;
};

const modalContextState = {
  modalProps: defaultValueModal,
  setModalProps: () => {},
  onOpen: () => {},
  onClose: () => {},
};

export const ModalContext =
  React.createContext<ModalContextType>(modalContextState);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = (
  { children },
  props
) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalProps, setModalProps] = useState<ModalProps>(defaultValueModal);

  useEffect(() => onClose, [onClose]);

  return (
    <ModalContext.Provider
      value={{ modalProps, setModalProps, onOpen, onClose }}
      {...props}
    >
      <Modal isOpen={isOpen} onClose={onClose} {...modalProps}>
        {children}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = React.useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within ModalProvider');
  }

  return context;
};
