import {
  Box,
  Button,
  Heading as CHeading,
  Flex,
  Link,
  Text,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Footer } from '~/components/Footer';
import { routes } from '~/constants/routes';

export interface ErrorProps {
  heading: ReactNode;
  subheading: ReactNode;
  text: ReactNode;
  imagesSet: string;
  imagesAccentColor: string;
  containerProps?: Object;
  noFooter?: boolean;
}

const ErrorPage = ({
  heading,
  subheading,
  text,
  imagesSet,
  imagesAccentColor,
  containerProps = {},
  noFooter = false,
}: ErrorProps) => {
  return (
    <Flex
      height={{ md: '100%' }}
      minH="100vh"
      flexDirection="column"
      position="relative"
      {...containerProps}
    >
      <Box flex="1">
        <Box
          px={{ base: 6, sm: 24 }}
          pr={{ base: 6, md: 'calc(50% - 6rem)', xl: 'calc(60% - 6rem)' }}
          h="100%"
        >
          <CHeading
            fontWeight="medium"
            size="3xl"
            color="primary.700"
            marginTop={{ base: 6, sm: 24 }}
          >
            {heading}
          </CHeading>
          <Text
            fontSize="2xl"
            color="primary.400 "
            fontWeight="normal"
            mb={10}
            mt={6}
          >
            {subheading}
          </Text>
          <Box
            px={5}
            py={4}
            fontSize="md"
            rounded="lg"
            mb={12}
            backgroundColor="neutral.20"
          >
            {text}
          </Box>
          <Link href={routes.default}>
            <Button
              variant="solid"
              colorScheme="primary"
              borderRadius={40}
              backgroundColor="primary.500"
              color="white"
            >
              Go to Homepage
            </Button>
          </Link>
        </Box>
        <Box position="absolute" right="10px" top="10%" zIndex="hide">
          <Box
            display={{
              base: 'none',
              md: 'block',
            }}
            as="img"
            srcSet={imagesSet}
            alt="Error"
            rounded="full"
            position="absolute"
            top="60%"
            right="50%"
          />
          <Box
            rounded="full"
            backgroundColor={imagesAccentColor}
            h="30px"
            w="30px"
          />
          <Box
            rounded="full"
            backgroundColor={imagesAccentColor}
            h="440px"
            w="440px"
          />
        </Box>
      </Box>
      {!noFooter && <Footer marginTop={{ md: 20, lg: 20 }} />}
    </Flex>
  );
};

export default ErrorPage;
