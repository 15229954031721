import { Icon, IconProps } from '@chakra-ui/react';

/**
 * TODO: In the future this should be updated in the YoutubeIcon
 * Note: This is used in the ManagedCreatorCard, we need a new
 * IconBox component to simulate the Icon styling in ManagedCreatorCard
 * and unify all social icons with this style.
 */
export const YoutubeNoBorderIcon: React.FC<IconProps> = props => (
  <Icon data-testid="youtube-icon" viewBox="0 0 14 10" {...props}>
    <path
      d="M13.1517 3.17171C13.1517 1.57615 11.8886 0.312988 10.293 0.312988H3.04647C1.4509 0.312988 0.187744 1.57615 0.187744 3.17171V6.5623C0.187744 8.15786 1.4509 9.42102 3.04647 9.42102H10.293C11.8886 9.42102 13.1517 8.15786 13.1517 6.5623V3.17171ZM8.8304 5.09969L5.57279 6.69526C5.43982 6.76174 5.04093 6.69526 5.04093 6.49581V3.17171C5.04093 3.03875 5.5063 2.90579 5.63927 2.97227L8.76392 4.7008C8.8304 4.83376 8.96337 5.03321 8.8304 5.09969Z"
      fill="currentColor"
    />
  </Icon>
);
