import { LegacyEvent, typeLegacyEvent } from './LegacyEvent';

export interface UserManagementLegacyEvent extends LegacyEvent {
  type: typeLegacyEvent.userManagement;
  payload: {
    pathname: string;
  };
}

export function isUserManagementLegacyEvent(
  evnt: LegacyEvent
): evnt is UserManagementLegacyEvent {
  return evnt?.type === typeLegacyEvent.userManagement;
}
