import { LegacyEvent, typeLegacyEvent } from './LegacyEvent';

export interface RefreshMeLegacyEvent extends LegacyEvent {
  type: typeLegacyEvent.refreshMe;
  payload: {};
}

export function isRefreshMeLegacyEvent(
  evnt: LegacyEvent
): evnt is RefreshMeLegacyEvent {
  return evnt?.type === typeLegacyEvent.refreshMe;
}
