import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';

import { IconBox } from '~/components/IconBox';
import {
  TiktokNoBorderIcon,
  YoutubeNoBorderIcon,
  InstagramNoBorderIcon,
} from '~/components/Icons';
import { SOCIAL_PLATFORMS_NAMES } from '~/constants/names';

export const FooterPartners: React.FC<BoxProps> = props => {
  return (
    <Box display="inline-flex" paddingRight={12} {...props}>
      <Flex alignItems="center" flexWrap="wrap">
        <IconBox
          backgroundColor="primary.300"
          boxSize={4}
          marginRight={2}
          icon={
            <TiktokNoBorderIcon
              aria-label={`${SOCIAL_PLATFORMS_NAMES.tiktok} Partner`}
              boxSize="0.6rem"
              color="white"
            />
          }
        />
        <IconBox
          backgroundColor="primary.300"
          boxSize={4}
          marginRight={2}
          icon={
            <InstagramNoBorderIcon
              aria-label={`${SOCIAL_PLATFORMS_NAMES.instagram} Partner`}
              boxSize="0.6rem"
              color="white"
            />
          }
        />
        <IconBox
          backgroundColor="primary.300"
          boxSize={4}
          marginRight={2}
          icon={
            <YoutubeNoBorderIcon
              aria-label={`${SOCIAL_PLATFORMS_NAMES.youtube} Partner`}
              boxSize="0.6rem"
              color="white"
            />
          }
        />
        <Text color="primary.300" fontSize="10">
          Partners
        </Text>
      </Flex>
    </Box>
  );
};
