import {
  ComponentSingleStyleConfig,
  defineStyleConfig,
} from '@chakra-ui/react';

export const Select: ComponentSingleStyleConfig = defineStyleConfig({
  baseStyle: {
    field: {
      display: 'flex',
      alignItems: 'center',
    },
    dialog: {
      backgroundColor: 'white',
    },
  },
});
