import { LegacyEvent, typeLegacyEvent } from './LegacyEvent';

export interface StartNavigateLegacyEvent extends LegacyEvent {
  type: typeLegacyEvent.startNavigate;
}

export function isStartNavigateLegacyEvent(
  evnt: LegacyEvent
): evnt is StartNavigateLegacyEvent {
  return evnt?.type === typeLegacyEvent.startNavigate;
}
