import { BrandInfo } from '../BrandInfo';

import { LegacyEvent, typeLegacyEvent } from './LegacyEvent';

export interface SwitchBrandLegacyEvent extends LegacyEvent {
  type: typeLegacyEvent.switchBrand;
  payload: BrandInfo;
}

export function isSwitchBrandLegacyEvent(
  evnt: LegacyEvent
): evnt is SwitchBrandLegacyEvent {
  return evnt?.type === typeLegacyEvent.switchBrand;
}
