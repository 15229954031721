export const globalStyles = (props: any) => ({
  html: { height: '100%', scrollBehavior: 'smooth' },
  body: {
    height: '100%',
    width: '100%',
    bg: 'white',
    color: 'primary.600',
    [`@media (min-width: ${props.theme.breakpoints.md})`]: {
      width: 'auto',
    },
  },
  '#__next': { height: '100%' },
  '.grecaptcha-badge': {
    bottom: '100px !important',
  },
  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  '.js-focus-visible :focus:not([data-focus-visible-added])': {
    outline: 'none',
    boxShadow: 'none',
  },

  '*, *::before, *::after': { borderColor: 'gray.400' },
  ':root': {
    '--font-family': props.theme.fonts.body,
  },
  /* Scrollbar styling for scrollable content */

  /* Works on Firefox */
  '.scrollbar-thin *': {
    scrollbarWidth: 'thin',
    scrollbarColor: `${props.theme.colors.primary[200]} transparent`,
  },

  /* Works on Chrome, Edge, and Safari */
  '.scrollbar-thin *::-webkit-scrollbar': {
    width: '4px',
  },

  '.scrollbar-thin *::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },

  '.scrollbar-thin *::-webkit-scrollbar-thumb': {
    backgroundColor: props.theme.colors.primary[200],
    borderRadius: '40px',
  },
  /* End scrollbar styling*/
});
