import { Icon, IconProps } from '@chakra-ui/react';

/**
 * TODO: In the future this should be updated in the InstagramIcon
 * Note: This is used in the ManagedCreatorCard, we need a new
 * IconBox component to simulate the Icon styling in ManagedCreatorCard
 * and unify all social icons with this style.
 */
export const InstagramNoBorderIcon: React.FC<IconProps> = props => (
  <Icon data-testid="instagram-icon" viewBox="0 0 10 10" {...props}>
    <path
      d="M7.17722 2.36287C6.91057 2.36287 6.68835 2.58448 6.68835 2.8504C6.68835 3.11633 6.91057 3.33794 7.17722 3.33794C7.44387 3.33794 7.66608 3.11633 7.66608 2.8504C7.66608 2.58448 7.48831 2.36287 7.17722 2.36287Z"
      fill="currentColor"
    />
    <path
      d="M5.04398 2.98334C3.93292 2.98334 2.99963 3.91409 2.99963 5.02212C2.99963 6.13015 3.93292 7.0609 5.04398 7.0609C6.15503 7.0609 7.08832 6.13015 7.08832 5.02212C7.08832 3.86976 6.15503 2.98334 5.04398 2.98334ZM5.04398 6.30744C4.3329 6.30744 3.75515 5.73126 3.75515 5.02212C3.75515 4.31298 4.3329 3.7368 5.04398 3.7368C5.75505 3.7368 6.3328 4.31298 6.3328 5.02212C6.3328 5.73126 5.75505 6.30744 5.04398 6.30744Z"
      fill="currentColor"
    />
    <path
      d="M6.64395 9.14404H3.35522C1.97751 9.14404 0.866455 8.036 0.866455 6.66204V3.38226C0.866455 2.0083 1.97751 0.900269 3.35522 0.900269H6.64395C8.02165 0.900269 9.13271 2.0083 9.13271 3.38226V6.66204C9.13271 8.036 8.02165 9.14404 6.64395 9.14404ZM3.35522 1.65373C2.42193 1.65373 1.66642 2.40719 1.66642 3.33794V6.61772C1.66642 7.54847 2.42193 8.30193 3.35522 8.30193H6.64395C7.57723 8.30193 8.33275 7.54847 8.33275 6.61772V3.33794C8.33275 2.40719 7.57723 1.65373 6.64395 1.65373H3.35522V1.65373Z"
      fill="currentColor"
    />
  </Icon>
);
