import { Box, BoxProps, Flex, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

import { CONTACT_EMAIL } from '~/constants/env';
import { routes } from '~/constants/routes';

export const FooterMenu: React.FC<BoxProps> = props => {
  return (
    <Box display="inline-flex" {...props}>
      <Flex alignItems="flex-end">
        <NextLink href={routes.termsAndConditions} passHref target="_blank">
          <Link marginLeft="auto">Website Terms</Link>
        </NextLink>

        <NextLink href={routes.privacyPolicy} passHref target="_blank">
          <Link marginLeft={8}>Privacy Policy</Link>
        </NextLink>

        <Link marginLeft={8} href={`mailto:${CONTACT_EMAIL}`}>
          Contact
        </Link>
      </Flex>
    </Box>
  );
};
