'use client';
import { ChakraProvider } from '@chakra-ui/react';
import { SSOProvider, useSSO } from '@orca/contexts-sso';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppProps } from 'next/app';

import { GlobalErrorBoundary } from '~/components/GlobalErrorBoundary';
import { AlertProvider } from '~/contexts/alert';
import { LegacyProvider } from '~/contexts/legacy';
import { ModalProvider } from '~/contexts/modal';
import customTheme from '~/styles/theme';
import Fonts from '~/styles/theme.fonts';
import { Sentry } from '~/utils/sentry';

function MyApp({ Component, pageProps }: AppProps) {
  const queryClient = new QueryClient();

  function SentryUserManager() {
    const { user } = useSSO();
    if (user) {
      Sentry.setUser(user);
    }
    return null;
  }

  return (
    <GlobalErrorBoundary>
      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV !== 'production' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}

        <SSOProvider>
          <ChakraProvider theme={customTheme} resetCSS>
            <SentryUserManager />
            <Fonts />
            <LegacyProvider>
              <ModalProvider>
                <AlertProvider>
                  <Component {...pageProps} />
                </AlertProvider>
              </ModalProvider>
            </LegacyProvider>
          </ChakraProvider>
        </SSOProvider>
      </QueryClientProvider>
    </GlobalErrorBoundary>
  );
}

export default MyApp;
