import { ErrorBoundary } from 'react-error-boundary';

import Error500 from '~/components/Error500';
import { ChildrenType } from '~/types';
import { captureException } from '~/utils/sentry';

export function GlobalErrorBoundary({ children }: ChildrenType) {
  const handleError = (error: Error, info: { componentStack: string }) => {
    const { message } = error;
    captureException(new Error(message), { errorInfo: info });
  };

  return (
    <ErrorBoundary fallbackRender={() => <Error500 />} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
}
