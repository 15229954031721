import { Flex, Heading, Image, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

interface LoaderProps {
  title?: string;
  description?: string;
}

const Loader: React.FC<LoaderProps> = ({ title, description }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Flex
          position="absolute"
          top="0"
          left="0"
          right="0"
          justifyContent="center"
          h="100%"
          flexDirection="column"
          alignItems="center"
          backgroundColor="rgba(255,255,255,0.4)"
        >
          <Image src="/static/images/loader.svg" alt="loader" />
          <Heading mb="1" color="primary.500">
            {title}
          </Heading>
          <Text color="primary.400" fontSize="xl">
            {description}
          </Text>
        </Flex>
      </motion.div>
    </AnimatePresence>
  );
};

export default Loader;
