export enum typeLegacyEvent {
  invalidateMe = 'legacy-invalidate-me',
  logout = 'legacy-logout',
  navigate = 'legacy-navigate',
  navigateToFoam = 'legacy-navigate-to-foam',
  refreshMe = 'legacy-refresh-me',
  startNavigate = 'legacy-navigate-start',
  switchBrand = 'legacy-switch-brand',
  userManagement = 'legacy-user-management',
}

export interface LegacyEvent {
  type: typeLegacyEvent;
  payload: any;
}
