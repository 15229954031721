import {
  Modal as CModal,
  ModalProps as CModalProps,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

/* how to use
  const { onOpen, setModalProps, onClose } = useModal();
  setModalProps({
    title: 'Delete something',
    modalBody: (
      <ModalConfirmation
        textBody="Are you sure you want to remove [User name] from [Brand Name]?."
        cancelButtonAction={onClose}
        actionButton={{
          buttonProps: {
            onClick: () => console.log('click'),
            bg: 'error.500',
            color: "white"
          },
          text: 'Remove',
        }}
      />
    ),
  });
  onOpen();
**/

interface ModalProps extends CModalProps {
  title?: string;
  modalBody: React.ReactElement;
  modalFooter?: React.ReactElement;
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  modalBody,
  modalFooter,
}) => {
  return (
    <CModal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{modalBody}</ModalBody>
        {modalFooter && <ModalFooter>{modalFooter}</ModalFooter>}
      </ModalContent>
    </CModal>
  );
};

export default Modal;
