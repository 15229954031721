var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/base.ts
import { Auth } from "@orca/api-auth";
import axios from "axios";
var instance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_ECHO_DOMAIN}/api/v1`,
  withCredentials: true,
  headers: { "X-Requested-With": "XMLHttpRequest" }
});
instance.interceptors.request.use((req) => {
  var _a, _b, _c, _d, _e;
  const accessToken = window.localStorage.accessToken;
  const refreshToken = window.localStorage.refreshToken;
  const excludeAuthorization = !!((_a = req.params) == null ? void 0 : _a.excludeAuthorization) || !!((_b = req.data) == null ? void 0 : _b.excludeAuthorization);
  const singleToken = ((_c = req.data) == null ? void 0 : _c.singleToken) || null;
  if (accessToken && refreshToken && !excludeAuthorization) {
    req.params = __spreadProps(__spreadValues({}, req.params), {
      accessToken,
      refreshToken
    });
  } else if (!accessToken && singleToken) {
    req.params = __spreadProps(__spreadValues({}, req.params), {
      singleToken
    });
  } else {
    (_d = req.params) == null ? true : delete _d.excludeAuthorization;
    (_e = req.data) == null ? true : delete _e.excludeAuthorization;
  }
  return req;
});
instance.interceptors.response.use(
  (response) => __async(void 0, null, function* () {
    return response;
  }),
  (error) => __async(void 0, null, function* () {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = window.localStorage.getItem(
          "refreshToken"
        );
        const newAccessToken = yield Auth.refreshAccessToken({ refreshToken });
        if (newAccessToken.errorCode) {
          location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/whalar/login`;
          return Promise.reject(error);
        }
        window.localStorage.setItem(
          "accessToken",
          newAccessToken.accessToken
        );
        originalRequest.params.accessToken = newAccessToken.accessToken;
        return instance(originalRequest);
      } catch (error2) {
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("refreshToken");
        location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/whalar/login`;
      }
    } else if (error.response.status === 401 && !originalRequest._retry) {
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      location.href = `${process.env.NEXT_PUBLIC_SSO_DOMAIN}/whalar/login`;
    }
    return Promise.reject(error);
  })
);
function request(method, url, data, axiosConfig) {
  return __async(this, null, function* () {
    try {
      const response = yield instance[method](
        url,
        method === "get" ? __spreadProps(__spreadValues({}, axiosConfig), {
          params: data
        }) : data
      );
      return response.data;
    } catch (error) {
      return error;
    }
  });
}
var requests = {
  get(url, data, axiosConfig) {
    return __async(this, null, function* () {
      return request("get", url, data, axiosConfig);
    });
  },
  post(url, data) {
    return __async(this, null, function* () {
      return request("post", url, data);
    });
  },
  put(url, data) {
    return __async(this, null, function* () {
      return request("put", url, data);
    });
  },
  patch(url, data) {
    return __async(this, null, function* () {
      return request("patch", url, data);
    });
  },
  delete(url, data) {
    return __async(this, null, function* () {
      return request("delete", url, data);
    });
  }
};

// src/constants/index.ts
var ROLES = /* @__PURE__ */ ((ROLES2) => {
  ROLES2["admin"] = "admin";
  ROLES2["user"] = "user";
  ROLES2["agency"] = "agency";
  return ROLES2;
})(ROLES || {});

// src/constants/routes.ts
var routes = {
  logout: "/logout",
  users: {
    me: "/users/me"
  },
  login: {
    switchBrand: "/login/switch-brand"
  },
  admin: {
    restoreIdentity: "/admin/restoreidentity",
    brands: {
      assumeIdentity: "/admin/brand/assumeidentity"
    },
    influencer: {
      assumeIdentity: "/admin/influencer/assumeidentity"
    }
  }
};

// src/index.ts
var LegacyApi = {
  users: {
    me: (creatorId, excludeAuthorization, singleToken) => requests.get(routes.users.me, {
      creatorId,
      excludeAuthorization,
      singleToken
    })
  },
  logout: () => requests.get(`${routes.logout}`, { excludeAuthorization: true }),
  login: {
    switchBrand: (brandUserId) => requests.post(
      `${routes.login.switchBrand}/${brandUserId}`,
      { excludeAuthorization: true }
    )
  },
  admin: {
    restoreIdentity: () => requests.post(
      routes.admin.restoreIdentity,
      { excludeAuthorization: true }
    ),
    brands: {
      assumeIdentity: (brandId) => requests.post(
        `${routes.admin.brands.assumeIdentity}/${brandId}`,
        { excludeAuthorization: true }
      )
    },
    influencer: {
      assumeIdentity: (influencerId) => requests.post(
        `${routes.admin.influencer.assumeIdentity}/${influencerId}`,
        { excludeAuthorization: true }
      )
    }
  }
};
export {
  LegacyApi,
  ROLES
};
