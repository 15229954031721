import { FlexProps } from '@chakra-ui/react';

import ErrorPage from '~/components/ErrorPage';

interface Props {
  containerProps?: FlexProps;
  noFooter?: boolean;
}

const Error500: React.FC<Props> = ({
  containerProps = {},
  noFooter = false,
}) => {
  return (
    <ErrorPage
      containerProps={containerProps}
      noFooter={noFooter}
      heading="500. Something went wrong…"
      subheading="Sorry, something went wrong. We are working to fix this error."
      text="Try to reload the page, or check back later."
      imagesAccentColor="rgba(110, 198, 14, 0.2)"
      imagesSet="/static/images/500_img.jpg, /static/images/500_imgx2.jpg 2x, /static/images/500_imgx3.jpg 3x"
    />
  );
};

export default Error500;
