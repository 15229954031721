import { Container, ContainerProps, Flex } from '@chakra-ui/react';

import { FooterCopyright, FooterMenu, FooterPartners } from './components';

export const Footer: React.FC<ContainerProps> = ({ ...props }) => {
  return (
    <Container
      backgroundColor="white"
      as="footer"
      paddingY={4}
      paddingBottom={6}
      maxWidth="inherit"
      boxShadow="0px -4px 3px rgba(0, 0, 0, 0.03)"
      {...props}
    >
      <Flex alignItems="center" flexWrap="wrap">
        <FooterCopyright
          color="primary.300"
          minWidth={{ base: '100%', xl: 'unset' }}
        />
        <FooterPartners
          color="primary.300"
          fontSize="10"
          mt={{ base: '2', md: 0 }}
          minWidth={{ base: '100%', md: '50%', xl: 'unset' }}
        />
        <FooterMenu
          marginLeft="auto"
          color="primary.300"
          fontSize="10"
          mt={{ base: '2', lg: 0 }}
          minWidth={{ base: '100%', lg: 'unset', xl: 'unset' }}
        />
      </Flex>
    </Container>
  );
};
