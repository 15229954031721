import { ComponentMultiStyleConfig /* theme */ } from '@chakra-ui/react';

export const Modal: ComponentMultiStyleConfig = {
  parts: [
    'body',
    'closeButton',
    'dialog',
    'dialogContainer',
    'footer',
    'header',
    'overlay',
  ],
  baseStyle: {
    dialog: {
      bg: 'white',
      color: 'primary.600',
      borderColor: 'primary.600',
    },
  },
  variants: {
    newMP: {
      body: {
        '&:first-of-type': {
          marginTop: '2',
          paddingTop: '0',
        },
        '&:last-child': { marginBottom: '0' },
        flexBasis: 'auto',
        height: '100%',
        marginBottom: '6',
        padding: '0 0.5rem 0 0',
      },
      closeButton: {
        borderRadius: '50%',
        height: '6',
        width: '6',
        '&:first-child': {
          alignSelf: 'end',
          position: 'relative',
          right: '0',
          top: '0',
        },
        '&:hover': {
          backgroundColor: 'primary.50',
        },
        '& > svg': {
          height: '0.625rem',
          width: '0.625rem',
        },
      },
      dialog: {
        alignSelf: { base: 'flex-end', md: 'center' },
        borderBottomLeftRadius: { base: '0', md: 'xl' },
        borderBottomRightRadius: { base: '0', md: 'xl' },
        borderTopLeftRadius: 'xl',
        borderTopRightRadius: 'xl',
        marginBottom: '0',
        maxHeight: { base: '100%', md: 'calc(100vh - 7rem)' },
        padding: '6',
      },
      dialogContainer: {
        height: '100%',
      },
      footer: {
        alignItems: 'center',
        flex: '1 0',
        justifyContent: 'space-between',
        paddingX: '0',
        paddingBottom: '0',
      },
      // Note: only affects `title` prop of our custom ModalHeader component
      header: {
        flex: '1 0',
        fontWeight: 'medium',
        padding: '0',
      },
      overlay: {
        bottom: { base: 0, md: 'initial' },
        top: { base: 'initial', md: 0 },
      },
    },
  },
};
