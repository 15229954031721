import { Box, BoxProps } from '@chakra-ui/react';

import { FoamLogo, WhalarLogo } from '~/components/Icons';

export const FooterCopyright: React.FC<BoxProps> = props => {
  return (
    <Box marginRight={12}>
      <WhalarLogo color={props.color} width={6} height={6} marginRight={4} />
      <FoamLogo color={props.color} width={6} height={6} />
    </Box>
  );
};
