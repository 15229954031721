import { LegacyEvent, typeLegacyEvent } from './LegacyEvent';

export interface LogoutLegacyEvent extends LegacyEvent {
  type: typeLegacyEvent.logout;
  payload: {
    pathname: string;
  };
}

export function isLogoutLegacyEvent(
  evnt: LegacyEvent
): evnt is LogoutLegacyEvent {
  return evnt?.type === typeLegacyEvent.logout;
}
