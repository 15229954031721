import { ErrorResponse } from '@orca/api';
import {
  AssumeIdentityResponse,
  LegacyApi,
  MeResponse,
} from '@orca/api-legacy';
import { useSSO } from '@orca/contexts-sso';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash';
import router from 'next/router';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import Error500 from '~/components/Error500';
import Loader from '~/components/Loader';
import { ECHO_DOMAIN } from '~/constants/env';
import { LOCALSTORAGE_KEYS } from '~/constants/local_storage';
import { ME_ENDPOINT_ROLES } from '~/constants/me_endpoint_roles';
import { QUERIES_KEY } from '~/constants/queries_key';
import { routes } from '~/constants/routes';
import { LegacyEvent } from '~/types/legacy-events/LegacyEvent';
import { isLogoutLegacyEvent } from '~/types/legacy-events/LogoutLegacyEvent';
import { isNavigateLegacyEvent } from '~/types/legacy-events/NavigateLegacyEvent';
import { isNavigateToFoamLegacyEvent } from '~/types/legacy-events/NavigateToFoamLegacyEvent';
import { isRefreshMeLegacyEvent } from '~/types/legacy-events/RefreshMeLegacyEvent';
import { isStartNavigateLegacyEvent } from '~/types/legacy-events/StartNavigateLegacyEvent';
import { isSwitchBrandLegacyEvent } from '~/types/legacy-events/SwitchBrandLegacyEvent';
import { isUserManagementLegacyEvent } from '~/types/legacy-events/UserManagementLegacyEvent';
interface LegacyInterface {
  me?: MeResponse;
  switchBrand: (brandId: string) => unknown | MeResponse;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  restoreIdentity: () => Promise<AssumeIdentityResponse | ErrorResponse>;
  assumeIdentityBrand: (
    brandId: string
  ) => Promise<AssumeIdentityResponse | ErrorResponse>;
  assumeIdentityCreator: (
    creatorId: string
  ) => Promise<AssumeIdentityResponse | ErrorResponse>;
  isGlobalLoading: boolean;
}

export const LegacyContext = React.createContext<LegacyInterface | null>(null);

export function LegacyProvider({ children }: { children: ReactNode }) {
  // globalLoading gets triggered on beforeonload event from echo
  // it reacts to any click the user makes inside the iframe
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(false);
  const { logout, singleToken } = useSSO();
  const queryClient = useQueryClient();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const {
    isLoading,
    error,
    data: me,
  } = useQuery({
    queryKey: [
      QUERIES_KEY.legacy.me,
      { creatorId: window.localStorage.creatorId || undefined },
    ],
    queryFn: () => {
      const creatorId = window.localStorage.creatorId || undefined;
      return LegacyApi.users.me(creatorId, true, singleToken);
    },
  });

  useEffect(() => {
    if (me && !me.userId) {
      logout();
      return;
    }
    /*
    As we enter this path with the singleToken query param, we want to refresh the app
    with a new url that includes the accessToken but not the singleToken.
    router.push() won't refresh the page as we're targeting the same page, so replacing 
    manually the query params, and doing a reload solves the problem
    */
    if (
      me &&
      me.userId &&
      me.role === ME_ENDPOINT_ROLES.influencer &&
      singleToken
    ) {
      const newUrl = `${routes.influencersEditProfile}?complete-profile&accessToken=${me.sessionTokens.accessToken}&refreshToken=${me.sessionTokens.refreshToken}`;
      window.location.assign(newUrl);
      window.localStorage.removeItem(LOCALSTORAGE_KEYS.singleTokenKey);
    } else if (
      // this condition cover the case of CS or others, using an approval link specifically for a creator
      me &&
      me.userId &&
      me.role !== ME_ENDPOINT_ROLES.influencer &&
      singleToken
    ) {
      window.location.assign(routes.default);
      window.localStorage.removeItem(LOCALSTORAGE_KEYS.singleTokenKey);
    }
  }, [me, logout, singleToken]);

  const switchBrand = useCallback(
    async (brandId: string, onError: () => void) => {
      const resSwitchBrand = await LegacyApi.login.switchBrand(brandId);
      if (resSwitchBrand?.success) {
        queryClient.invalidateQueries({
          queryKey: [QUERIES_KEY.legacy.me],
        });
      } else onError;
    },
    []
  );

  const restoreIdentity = async () => {
    const result = await LegacyApi.admin.restoreIdentity();
    queryClient.invalidateQueries({
      queryKey: [QUERIES_KEY.legacy.me],
    });
    return result;
  };

  const assumeIdentityBrand = async (brandId: string) => {
    const result = await LegacyApi.admin.brands.assumeIdentity(brandId);
    queryClient.invalidateQueries({
      queryKey: [QUERIES_KEY.legacy.me],
    });
    return result;
  };
  const assumeIdentityCreator = async (creatorId: string) => {
    const result = await LegacyApi.admin.influencer.assumeIdentity(creatorId);
    queryClient.invalidateQueries({
      queryKey: [QUERIES_KEY.legacy.me],
    });
    return result;
  };

  useEffect(() => {
    const handler = async (event: MessageEvent) => {
      if (event.source === iframeRef.current?.contentWindow) {
        const evnt: LegacyEvent = event.data;
        if (isStartNavigateLegacyEvent(evnt)) {
          setIsGlobalLoading(true);
        }

        if (isNavigateLegacyEvent(evnt)) {
          setIsGlobalLoading(false);
          if (evnt.payload.pathname === '/logout') {
            logout();
          } else if (router.pathname !== evnt.payload.pathname) {
            try {
              window.history.pushState({}, '', evnt.payload.pathname);
              document.title = evnt.payload.title;
            } catch (e) {
              console.error(e);
            }
          }
        }

        if (isRefreshMeLegacyEvent(evnt)) {
          setIsGlobalLoading(false);
          queryClient.invalidateQueries({
            queryKey: [QUERIES_KEY.legacy.me],
          });
        }

        if (isUserManagementLegacyEvent(evnt)) {
          router.push(routes.brandSettings);
        }

        if (isSwitchBrandLegacyEvent(evnt)) {
          setIsGlobalLoading(true);
          if (
            evnt.payload?.brandId &&
            me?.brand?.brandId !== evnt.payload.brandId
          ) {
            await switchBrand(evnt.payload.brandId, console.error);
            setIsGlobalLoading(false);
          }
          // Reload or navigate
          iframeRef.current.src = `${ECHO_DOMAIN}/dashboard`;
        }

        if (isLogoutLegacyEvent(evnt)) {
          LegacyApi.logout().finally(() => {
            logout();
          });
        }

        if (isNavigateToFoamLegacyEvent(evnt)) {
          window.location.assign(evnt.payload.href);
        }
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, [iframeRef, logout, me?.brand?.brandId, queryClient, switchBrand]);

  const providerValue = {
    me,
    switchBrand,
    iframeRef,
    restoreIdentity,
    assumeIdentityBrand,
    assumeIdentityCreator,
    isGlobalLoading,
  } as LegacyInterface;

  return (
    <>
      <LegacyContext.Provider value={providerValue}>
        {!isLoading && !isNil(error) && <Error500 />}

        {isLoading ? <Loader /> : !error && children}
      </LegacyContext.Provider>
    </>
  );
}

export const useLegacy = () => {
  const context = React.useContext(LegacyContext);

  if (!context) {
    throw new Error('useSignUp must be used within LegacyProvider');
  }

  return context;
};
