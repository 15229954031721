import { Icon, IconProps } from '@chakra-ui/react';

/**
 * TODO: In the future this should be updated in the TiktokIcon
 * Note: This is used in the ManagedCreatorCard, we need a new
 * IconBox component to simulate the Icon styling in ManagedCreatorCard
 * and unify all social icons with this style.
 */
export const TiktokNoBorderIcon: React.FC<IconProps> = props => (
  <Icon data-testid="tiktok-icon" viewBox="0 0 12 14" {...props}>
    <path
      d="M11.2856 5.76999C11.1527 5.76999 11.0862 5.76999 10.9532 5.76999C9.82303 5.76999 8.75932 5.23814 8.16098 4.24091V9.36002C8.16098 11.421 6.49893 13.1495 4.37151 13.1495C2.31056 13.1495 0.582031 11.4874 0.582031 9.36002C0.582031 7.2326 2.24408 5.57055 4.37151 5.57055C4.43799 5.57055 4.50447 5.57055 4.63743 5.57055V7.43204C4.57095 7.43204 4.50447 7.43204 4.37151 7.43204C3.30779 7.43204 2.44353 8.29631 2.44353 9.36002C2.44353 10.4237 3.30779 11.288 4.37151 11.288C5.43522 11.288 6.36597 10.4237 6.36597 9.36002V0.650879H8.16098C8.36043 2.24645 9.62358 3.5096 11.2192 3.64257L11.2856 5.76999Z"
      fill="currentColor"
    />
  </Icon>
);
