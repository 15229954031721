import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Table: ComponentMultiStyleConfig = {
  parts: ['table', 'th'],
  baseStyle: {
    table: {
      borderRadius: '12px',
      overflow: 'hidden',
      fontSize: '14px',
    },
    th: {
      borderBottom: '0px',
      borderStyle: 'hidden',
      backgroundColor: 'neutral.100',
    },
  },
};
