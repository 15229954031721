export const QUERIES_KEY = {
  legacy: {
    me: 'legacy.me',
  },
  orca: {
    brandUsers: {
      list: 'orca.brandUsers.list',
    },
    brands: {
      list: 'orca.brands.list',
    },
  },
  auth: {},
};
